import axios from 'axios'
import { LOG_ALL, LOG_GET, LOG_PUT, LOG_DEL } from '../types'
import moment from 'moment'

export const logAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + '/log').then((res) => {
      console.log('Request Server to Get All Logs')
      dispatch({ type: LOG_ALL, payload: res.data })
    })
  }
}

export const logOnEachStation = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/log/station/' + stationId)
      .then((res) => {
        console.log('Request Server to Get All Logs')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logOnDaySpecific = (
  stationId,
  startDay,
  finishDay,
  samplingTime
) => {
  return async (dispatch) => {
    const formattedStartDay = moment(startDay).format('YYYY-MM-DD')
    const formatFinishDay = moment(finishDay).format('YYYY-MM-DD')
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          '/log/specific/' +
          stationId +
          '/' +
          formattedStartDay +
          '/' +
          formatFinishDay +
          '/' +
          samplingTime
      )
      .then((res) => {
        console.log('Request Server to Get All Logs')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logSummarize = (startDay, finishDay) => {
  return async (dispatch) => {
    //const formattedStartDay = moment(startDay).format('YYYY-MM-DD')
    //const formatFinishDay = moment(finishDay).format('YYYY-MM-DD')
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          '/log/summarizelog' +
          '/' +
          startDay +
          '/' +
          finishDay
      )
      .then((res) => {
        console.log('Request Server to Get summarize logs')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logLastestTwenty = (stationId, { fetchPrediction = '' } = {}) => {
  return async (dispatch) => {
    await axios
      .get(
        process.env.REACT_APP_API_URL +
          '/log/last20/' +
          stationId +
          '?fetchPrediction=' +
          fetchPrediction
      )
      .then((res) => {
        console.log('Request Server to Get Lastest 20 Logs')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logByHour = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/log/last/hour/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Lastest 20 Logs')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logByDay = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/log/last/day/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Last Day')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logByWeek = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/log/last/week/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Last Week')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logByWeekAll = () => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/log/last/weekall')
      .then((res) => {
        console.log('Request Server to Get Last Week all station')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logByMonth = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/log/last/month/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Last Month')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logByYear = (stationId) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/log/last/year/' + stationId)
      .then((res) => {
        console.log('Request Server to Get Last Year')
        dispatch({ type: LOG_ALL, payload: res.data })
      })
  }
}

export const logGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/log/' + payload)
      .then((res) => {
        console.log('Request Server to Get an Logs')
        if (res.data) {
          dispatch({ type: LOG_GET, payload: res.data })
        } else {
          dispatch({ type: LOG_GET, payload: null })
        }
      })
  }
}

export const logPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + '/log/' + id, payload)
      .then((res) => {
        console.log('Request Server to put an Logs')

        dispatch({ type: LOG_PUT, payload: null })
      })
  }
}
export const logDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + '/log/' + payload)
      .then((res) => {
        console.log('Request Server to Delete an Logs')
        dispatch({ type: LOG_DEL, payload: null })
      })
  }
}
